

























































































































.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  background-color: #f3f3f3;
  padding: 50px 0;

  > div {
    width: 100%;
    overflow: hidden;

    > .el-carousel {
      display: flex;
      width: 1600px;
      height: 100%;
      margin: 0 auto;
      flex-wrap: nowrap;
      //transition: transform 0.5s linear;
      ::v-deep .el-carousel__container {
        height: 330px !important;
        margin: 0 auto;
        width: 100%;
        .el-carousel__arrow--left {
          left: 150px;
        }
        .el-carousel__arrow--right{
          right: 150px;
        }
      }
      ::v-deep .el-carousel__indicators {
        > li {
          >button {
            background-color: #ccc;
          }
        }
      }
      .el-carousel__item {
        width: 100%;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        flex: 0 0 100%;

        > div:nth-child(1) {
          color: #333;
          width: 20%;

          > div:nth-child(1) {
            font-size: 24px;
          }

          > div:nth-child(2) {
            font-size: 16px;
          }

          > div:nth-child(3) {
            font-size: 16px;
            line-height: 24px;
            width: 80%;
            margin-top: 50px;

            > p {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
              overflow-y: hidden;
            }
          }

          > div:nth-child(4) {
            width: 50%;
            height: 35px;
            line-height: 35px;
            font-size: 16px;
            text-align: center;
            color: #fff;
            margin-top: 30px;
            background-color: #000096;

            > a {
              display: inline-block;
              width: 100%;
            }
          }
        }

        > div:nth-child(2) {
          display: flex;
          justify-content: center;
          width: 25%;

          > img {
            object-fit: contain;
          }
        }

        > div:nth-child(3) {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          width: 20%;

          > div {
            width: 90px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-left: solid 2px #a09d9d;

            > img {
              background-color: rgba(0, 0, 0, 0.4);
              width: 80px;
              height: 80px;
              object-fit: contain;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px){
  .slide{
    >div{
      >.el-carousel{
        .el-carousel__item{
          >div:nth-child(1){
            width: 33%;
            >div:nth-child(1){
              font-size: 20px;
              margin-top: 30px;
            }
            >div:nth-child(4){
              position: absolute;
              bottom: 60px;
            }
          }
        }
      }
    }
  }
}
