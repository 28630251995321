
















































.application {
  position: relative;

  > img {
    width: 100%;
    height: 600px;
  }

  > div:nth-child(1) {
    position: absolute;
    width: 100%;
    height: 600px;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  > div:nth-child(2) {
    position: relative;
    margin: 0 auto;
    width: 1200px;
    > div:nth-child(1) {
      position: relative;
    }

    > div:nth-child(2) {
      position: absolute;
      width: 330px;
      height: 460px;
      background-color: #F7F8F8;
      // calc里运算符两边要加空格
      right: 0;
      top: 220px;
      z-index: 20;

      > div:nth-of-type(1) {
        position: relative;
        top: -40px;
        width: 39%;
        line-height: 28px;
        margin: 0 auto;
        padding: 23px 24px;
        color: #fff;
        background-color: #000096;
        text-align: center;

        > span:nth-child(1) {
          font-size: 34px;
        }

        > span:nth-child(2) {
          font-size: 28px;
        }
      }

      > div:nth-of-type(2) {
        position: absolute;
        bottom: 30px;
        height: 30px;
        line-height: 30px;
        width: 30%;
        left: 35%;
        border: 1px #000096 solid;
        text-align: center;

        &:hover {
          background-color: #000096;

          > a {
            color: #fff;
            font-size: 14px;
            display: inline-block;
            width: 100%;
          }
        }

        > a {
          color: #000096;
          font-size: 14px;
          display: inline-block;
          width: 100%;
        }
      }

      > ul {
        padding: 0 20px;
        margin-top: 20px;

        > li {
          line-height: 40px;
          border-bottom: 1px #ccc solid;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          > a {
            color: #3E3A39;
            font-size: 14px;
          }
        }
      }
    }
  }
}
