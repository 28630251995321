





























.container {
  width: 100%;
  height: 294px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  transition: transform 0.2s linear;

  &:hover {
    transform: translate(0, -20px);

    > div:nth-child(2) {
      > .title-wrapper{
        bottom: 50px;
      }
    }
  }

  > div:nth-child(1) {
    width: 100%;
    padding: 20px 0;
    text-align: center;

    > img {
      position: relative;
      object-fit: contain;
    }
  }

  > div:nth-child(2) {
    width: 100%;
    height: 320px;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0) 74%);
    .title-wrapper {
      position: absolute;
      bottom: -20px;
      transition: bottom 0.2s linear;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      color: #fff;
      font-size: 20px;
      text-align: center;
      > div:nth-child(1) {
        line-height: 22px;
      }
      > div:nth-child(2) {
        margin-top: 30px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        padding: 0 30px;
        background-color: #000096;
      }
    }
  }

}
