























































































































































































































































































































































































































































































































































































section {
  > .atdsvertising {
    position: relative;
    > div:nth-child(1) {
      position: absolute;
      display: flex;
      justify-content: center;
      left: 40%;
      width: 20%;
      bottom: 24vh;

      > i {
        width: 15px;
        height: 6px;
        margin: 0 5px;
        transform: skew(-30deg);
        border-radius: 2px;
        cursor: pointer;
        z-index: 100;
      }

      .click {
        background: rgba(255, 255, 255, 1);
      }

      .unclick {
        background: rgba(255, 255, 255, 0.5);
      }
    }

    > div:nth-child(2) {
      position: absolute;
      width: 100%;
      bottom: 6px;
      z-index: 1;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      padding: 30px 0;
      color: #fff;

      > div {
        padding: 0 50px;
        text-align: center;
        height: 100px;
        width: 140px;

        &:not(&:last-of-type) {
          border-right: 1px #c5c5c5 dotted;
        }

        > div:nth-child(1) {
          height: 48px;
          > i {
            font-size: 46px;
            line-height: 46px;
            font-family: SourceHanSansCN-Bold, sans-serif;
          }

          > span {
            font-size: 14px;
            margin-left: 4px;
          }
        }

        > div:nth-child(2) {
          width: 120px;
          line-height: 22px;
          font-size: 16px;
          padding: 0 10px;
        }
      }
    }

    > div:nth-child(3) {
      overflow: hidden;

      > i {
        position: absolute;
        display: block;
        top: 0;
        width: 160px;
        height: 100%;
        z-index: 1;

        &:nth-child(1) {
          left: 0;
        }

        &:nth-child(3) {
          right: 0;
        }
      }

      > ul {
        display: flex;
        flex-wrap: nowrap;
        transition: transform 1s linear;
      }
    }
  }

  > .product-center {
    > div:nth-child(1) {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      padding-top: 110px;
      padding-bottom: 65px;
    }

    > div:nth-child(2) {
      display: flex;
      justify-content: center;
      width: 1200px;
      margin: 0 auto;
      margin-bottom: 65px;

      > div {
        flex: 0 0 240px;
        text-align: center;
      }

      .current:nth-child(1) {
        > div:first-child {
          background-image: url("../assets/img/quipment_cur.svg") !important;
        }
      }

      .current:nth-child(2) {
        > div:first-child {
          background-image: url("../assets/img/vehicle_cur.png") !important;
        }
      }

      .current:nth-child(3) {
        > div:first-child {
          background-image: url("../assets/img/module_cur.png") !important;
        }
      }

      .current:nth-child(4) {
        > div:first-child {
          background-image: url("../assets/img/monitor_cur.png") !important;
        }
      }

      .current:nth-child(5) {
        > div:first-child {
          background-image: url("../assets/img/cloudPlatform_cur.png") !important;
        }
      }

      > div:nth-child(1) {
        > div:first-child {
          width: 100%;
          height: 59px;
          background: url("../assets/img/quipment.svg") no-repeat center;
          background-size: contain;
        }

        &:hover {
          > div:first-child {
            background-image: url("../assets/img/quipment_cur.svg");
          }
        }
      }

      > div:nth-child(2) {
        > div:first-child {
          width: 100%;
          height: 59px;
          background: url("../assets/img/vehicle.png") no-repeat center;
          background-size: contain;
        }

        &:hover {
          > div:first-child {
            background-image: url("../assets/img/vehicle_cur.png");
          }
        }
      }

      > div:nth-child(3) {
        > div:first-child {
          width: 100%;
          height: 59px;
          background: url("../assets/img/module.png") no-repeat center;
          background-size: contain;
        }

        &:hover {
          > div:first-child {
            background-image: url("../assets/img/module_cur.png");
          }
        }
      }

      > div:nth-child(4) {
        > div:first-child {
          width: 100%;
          height: 59px;
          background: url("../assets/img/monitor.png") no-repeat center;
          background-size: contain;
        }

        &:hover {
          > div:first-child {
            background-image: url("../assets/img/monitor_cur.png");
          }
        }
      }

      > div:nth-child(5) {
        > div:first-child {
          width: 100%;
          height: 59px;
          background: url("../assets/img/cloudPlatform.png") no-repeat center;
          background-size: contain;
        }

        &:hover {
          > div:first-child {
            background-image: url("../assets/img/cloudPlatform_cur.png");
          }
        }
      }

      > div {
        cursor: pointer;
        margin-right: 10px;

        > div:nth-child(2) {
          color: #333;
          font-size: 18px;
          margin-top: 5px;
        }
      }
    }
  }

  > .industry-application {
    margin-bottom: 200px;

    .industry {
      width: 1200px;
      position: relative;
      margin: 0 auto;
      > div:first-child {
        //width: 1200px;
        position: absolute;
        top: 120px;
        z-index: 5;

        > a {
          color: #fff;
        }

        > p {
          font-size: 20px;
          line-height: 10px;
          color: #fff;
        }

        > div {
          display: flex;
          cursor: pointer;
          position: relative;
          top: 140px;

          > ul {
            display: flex;
            align-items: center;

            > li {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #fff;
              opacity: 0.2;
              margin: 0 3px;
            }

            .current {
              width: 16px;
              height: 16px;
              opacity: 1;
            }
          }

          > div {
            width: 30px;
            height: 30px;
            right: 15%;
            background: url("../assets/img/btn-pre.svg") no-repeat center;
            background-size: contain;
            margin: 0 5px;
          }

          > div:nth-of-type(2) {
            transform: rotate(180deg);
          }
        }
      }

      > ul {
        position: absolute;
        top: 450px;
        z-index: 10;
        display: flex;

        > li {
          margin-right: 15px;
          cursor: pointer;
          position: relative;

          > div {
            position: absolute;
            top: 180px;
            color: #fff;
            font-size: 22px;
            text-align: center;
            line-height: 45px;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
    .application-phone {
      display: none;
      > div {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        padding-top: 100px;
        padding-bottom: 50px;
      }
      > ul {
        > li {
          max-width: 490px;
          margin: 0 auto;
        }
      }
    }
  }

  > .news-center {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 100px;
    > div:nth-child(1) {
      padding-top: 60px;
      display: flex;
      justify-content: center;

      > div:nth-child(1) {
        position: relative;
        margin-top: 110px;
        width: 435px;

        > div:nth-child(2) {
          width: 452px;
          margin-top: 35px;
          padding: 24px 24px 40px 24px;
          position: relative;
          background-color: #000096;
          z-index: 10;

          > div {
            color: #fff;
          }

          > div:nth-child(1) {
            font-size: 10px;
          }

          > div:nth-child(2) {
            margin-top: 7px;
            line-height: 20px;
            font-size: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          > p {
            color: #fff;
            font-size: 14px;
            line-height: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          > ul {
            display: flex;

            > li {
              > div {
                width: 8px;
                height: 5px;
                background-color: #fff;
                margin: 0 2px;
                opacity: 0.5;
                cursor: pointer;
              }

              .current {
                width: 22px;
                opacity: 1;
              }
            }
          }
        }
      }

      > div:nth-child(2) {
        width: 765px;
        overflow: hidden;
        z-index: 1;

        > ul {
          display: flex;
          transition: transform 0.5s linear;

          > li {
            > a {
              > img {
                width: 765px;
                height: 450px;
              }
            }
          }
        }
      }
    }

    > div:nth-child(2) {
      width: 1200px;
      margin: 63px auto;

      > div:nth-child(1) {
        > ul {
          font-size: 18px;

          > li {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > a {
              color: #333333;
              line-height: 45px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            > span {
              display: block;
              width: 250px;
              text-align: right;
              font-size: 17px;
              font-weight: 400;
            }
          }
        }
      }

      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > a {
          width: 100px;
          height: 30px;
          line-height: 30px;
          background-color: #000096;
          color: #fff;
          font-size: 14px;
          display: inline-block;
          text-align: center;
        }
      }
    }
    > div:nth-child(3) {
      display: none;
    }
  }

  > .about-us {
    margin-bottom: 332px;
    width: 100%;
    height: 360px;
    background: url("../assets/img/aboutus.jpg") no-repeat center;
    background-size: 100% 360px;
    text-align: center;
    >div {
      width: 1200px;
      margin: 0 auto;
      > a {
        >div {
          padding: 60px 0;
        }
      }
      > p {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        font-size: 18px;
        line-height: 26px;
        color: #333;
      }
      > div {
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 40px 0 auto;

        > div {
          > a {
            display: inline-block;

            div {
              text-align: center;
              color: #333;
              font-size: 20px;
              margin-top: 20px;
            }
          }
        }
      }
    }

  }

  > .introduce {
    width: 100%;
    position: fixed;
    padding: 28px 0 42px 0;
    z-index: 100;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);

    > div:first-child {
      text-align: right;
      display: flex;
      justify-content: center;
      width: 1200px;
      margin: 0 auto;
      color: #fff;

      > div {
        margin: 0;
      }

      > div:nth-child(1) {
        margin-right: 120px;
        > div:nth-child(1) {
          font-size: 14px;
          line-height: 1.5;
        }

        > div:nth-child(2) {
          font-size: 30px;
        }

        > div:nth-child(3) {
          font-size: 14px;
        }
      }

      > div:nth-child(2) {
        margin-right: 165px;
        align-items: center;
        display: flex;

        > i {
          display: inline-block;
          width: 45px;
          height: 45px;
          background: #fff url("../assets/img/tel.svg") no-repeat center;
          background-size: 60%;
          border-radius: 50%;
        }

        > div {
          margin-left: 20px;

          > div:nth-child(1) {
            font-size: 14px;
          }

          > div:nth-child(2) {
            font-size: 24px;
          }
        }
      }
    }

    > i {
      width: 30px;
      height: 30px;
      display: inline-block;
      position: absolute;
      z-index: 1;
      top: 15%;
      right: 20%;
      background: url("../assets/img/close.svg");
      background-size: cover;
      cursor: pointer;
    }
  }
}
@media (max-width: 767px) {
  section {
    > .atdsvertising {
      position: relative;
      > div:nth-child(1) {
        bottom: 4vh;
      }
      > div:nth-child(2) {
        display: none;
      }
      > div:nth-child(3) {
        height: 60vw;
        > ul {
          height: 100%;
          > li {
            img {
              height: 100%;
            }
          }
        }
      }
    }
    > .product-center {
      > div {
        padding-top: 20px !important;
        width: 100% !important;
      }
      > div:nth-child(2) {
        width: 90% !important;
        //display: none;
        > div {
          flex: 0 0 20%;
          margin: 0;
          > div:nth-child(2) {
            font-size: 15px;
          }
        }
      }
    }
    > .industry-application {
      padding: 0 15px;
      margin-bottom: 100px;
      > .application {
        display: none;
      }
      > .application-phone {
        display: block;
      }
    }
    > .news-center {
      width: 100%;
      > div:nth-child(1),
      > div:nth-child(2) {
        display: none;
      }
      > div:nth-child(3) {
        display: block;
        > div:nth-child(1) {
          width: 100%;
          margin: 0 auto;
          text-align: center;
          padding-bottom: 50px;
        }
        > ul {
          text-align: center;
          border: solid 1px rgb(238, 238, 238);
          margin-bottom: 10px;
          > li {
            margin: 20px 0 10px 0;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    > .about-us {
        padding: 30px 0;
        margin-bottom: 200px;
        >div{
          width: 100%;
          > p {
            width: 100% !important;
            margin: 0 !important;
          }
          .container {
            padding: 10px 0;
          }
          > div {
            width: 100%;
            top: 0;
            > div:nth-child(1) {
              margin: 0;
              img {
                width: 100%;
              }
            }
            > div:nth-child(2),
            > div:nth-child(3) {
              display: none;
            }
          }
        }
        
     
    }

    > .introduce {
      display: none;
    }
    ::v-deep .el-carousel {
      width: 100% !important;
    }
    ::v-deep .el-carousel__arrow--left {
      left: 5vw !important;
    }
    ::v-deep .el-carousel__arrow--right {
      right: 5vw !important;
    }
    ::v-deep .el-carousel__item {
      > div:nth-child(1) {
        > div {
          z-index: 99;
        }
        > div:nth-child(2),
        > div:nth-child(3) {
          display: none;
        }
        > div:nth-child(4) {
          width: 66px !important;
        }
      }
      > div:nth-child(2) {
        width: 50% !important;
        > img {
          width: 100%;
        }
      }
      > div:nth-child(3) {
        display: none !important;
      }
    }
    ::v-deep .el-carousel__indicators {
      display: flex;
    }
  }
}
